.calendar1 {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
    height: 100%;
    width: 90rem;
}

.calendar-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
}

.date-range h2 {
    margin: 0;
}


.nav-buttons button,
.navigation button {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #ddd;
    background-color: #fff;
    cursor: pointer;
}

.nav-buttons button:hover,
.navigation button:hover {
    background-color: #eee;
}

.nav-buttons button.active {
    background-color: #ddd;
    font-weight: bold;
}


.calendar-nav button {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px 10px;
    cursor: pointer;
}

.calendar-nav h2 {
    margin: 0;
    font-size: 18px;
}

.calendar-header {
    display: grid;
    grid-template-columns: repeat(14, 100px);
    background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;
}

.calendar-header-cell {
    padding: 10px;
    text-align: center;
    font-weight: bold;
    border-right: 1px solid #ddd;
}

.calendar-body {
    display: grid;
    grid-template-columns: auto 1fr;
}

.calendar-body-container {
    user-select: none;
}

.resizer {
    transition: background-color 0.3s;
}

.resizer:hover {
    background-color: #bbb !important;
}


.calendar-row {
    display: grid;
    grid-template-columns: repeat(14, 50px);
    border-bottom: 1px solid #ddd;
}

.calendar-cell {
    min-height: 40px;
    border-right: 1px solid #ddd;
    padding: 5px;
    position: relative;
}

.calendar-event {
    background-color: #3174ad;
    color: white;
    padding: 2px 5px;
    margin-bottom: 2px;
    border-radius: 3px;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}




.event-time {
    font-size: 10px;
}

/* Responsive design */
@media (max-width: 768px) {

    .calendar-header-cell,
    .calendar-cell {
        padding: 5px;
        font-size: 12px;
    }

    .property-name {
        padding: 5px;
        font-size: 12px;
    }

    .calendar-event {
        font-size: 10px;
    }
}

/* Custom scrollbar for better aesthetics */
.calendar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.calendar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.calendar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.calendar::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.transition-height {
    transition: height 0.3s ease;
    overflow: hidden;
  }
  

  