.table-calendar-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.table-calendar {
    width: 83rem !important;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
}

.table-calendar .p-datatable-wrapper {
    overflow: auto;
    width: 100% !important;
    height: calc(100vh - 250px);
}

.table-calendar .p-datatable-thead {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: white;
}

.table-calendar .p-frozen-column {
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 2;
}

.table-calendar th,
.table-calendar td {
    border: 1px solid #dee2e6;
    padding: 0.5rem;
    position: relative;
}

.table-calendar th {
    font-size: 11px;
}

/* Rest of the original CSS remains the same */
.group-header-cell {
    background-color: #C8BFE7;
    display: flex;
    align-items: center;
    font-weight: bold;
    height: 100%;
    padding: 0;
}

.group-header{
    height: 30px !important;
}

.group-header-icon {
    margin-right: 5px;
    background-color: #66dac3;
    margin-bottom: 7px;
    color: white;
}

.property-row {
    height: 60px;
}

.property-cell {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 2px 10px;
    gap: 20px;
}

.property-icon {
    color: #666;
    margin-right: 5px;
}

.property-name {
    font-weight: bold;
    font-size: 13px;
}

.property-status {
    font-size: 13px;
    color: #666;
}

.last-service {
    font-size: 12px;
    color: #888;
}

.event-container {
    position: relative;
    height: 87px;
    width: 100%;
}

.table-calendar .region-row {
    height: 35px;
}

.event-duration,
.event-checkin,
.event-checkout,
.event-cleaning {
    font-size: 0.8em;
}

.task-button,
.bkg-button {
    background: none;
    border: none;
    color: #4CAF50;
    cursor: pointer;
    font-size: 12px;
    margin-right: 10px;
    padding: 0;
}

.task-button i,
.bkg-button i {
    font-size: 10px;
    margin-right: 2px;
}

.half-day-line {
    position: absolute;
    top: 0;
    right: 50%;
    width: 100%;
    height: 100%;
    border-right: 0.5px dashed #dee2e6;
}
.half-day-line-min {
    position: absolute;
    top: 0;
    right: 50%;
    width: 100%;
    height: 80%;
    border-right: 0.5px dashed #dee2e6;
}

.calendar-header-cell {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.calendar-header-cell::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-top: 1px dashed #999;
}

@media (max-width: 768px) {
    .calendar-header-cell,
    .calendar-cell {
        padding: 5px;
        font-size: 12px;
    }

    .property-name {
        padding: 5px;
        font-size: 12px;
    }

    .calendar-event {
        font-size: 10px;
    }
}

.calendar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.calendar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.calendar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.calendar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media (min-width: 1200px) {
    .table-calendar {
        border-collapse: separate;
        border-spacing: 0;
        width: 100% !important;
        overflow-x: auto;
    }
}

.staff-task-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 2px;
}

.staff-task {
    font-size: 0.7em;
    color: rgb(79, 79, 79);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 41px;

    
}
.staff-task.accepted {
    color: green;
}

.staff-task.not-accepted {
    color: orange;
}

.resizable-column {
    position: relative;
    overflow: hidden;
    resize: horizontal;
    min-width: 200px;
    max-width: 600px;
}

.resizable-column::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 5px;
    cursor: col-resize;
    background-color: #e0e0e0;
}

.resizable-column:hover::after {
    background-color: #66dac3;
}

.p-column-title {
    width: 100%;
}