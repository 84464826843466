@tailwind base;
@tailwind components;
@tailwind utilities;

.css-gcc2o7-MuiButton-startIcon>*:nth-of-type(1) {
  font-size: 22px !important;
  /* Your desired font-size */
}

.css-gcc2o7-MuiButton-startIcon {
  margin: 0 !important;
}

.css-1xzew2e-MuiButtonBase-root-MuiButton-root:focus:not(:hover) {
  background-color: #2ac9c9 !important;
}

.spanDetails,
strong {
  font-size: small;
}

.cleannessStatus {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: 2px;
}

.cleannessStatus.yellow {
  background: #e6e600;
}

.cleannessStatus.red {
  background: #ff0000;
}

.cleannessStatus.green {
  background: #16f816;
}

/* calendar */

.calendar {
  height: 750px;
  overflow: 'auto';
  background: white;
}

.CalendarNavigator {
  display: flex;
  align-items: center;
}

.toolbarNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.buttonTolbar {
  color: #2f9f9f !important;
}

.CalendarMonthlyFilter-switcher {
  display: flex;
  padding-left: 10px;
}

.CalendarMonthlyFilter-right {
  padding-left: 10px;
  margin-left: auto;
  display: flex;
  align-items: center;
  /* flex: 1 1 0%; */
}

/* .CalendarMonthlyFilter-things {
    flex: 1;
} */
.rbc-now .rbc-button-link {
  background-color: black;
  color: #ffffff;
  border-radius: 21px;
  width: 36px;
  margin: 10px;
}

.rbc-button-link {
  float: left;
  margin: 10px;
}

.rbc-off-range-bg {
  background: #f9fafd;
}

.rbc-header {
  font-size: 76%;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background: none;
  border: none;
}

.rbc-event-continues-prior {
  user-select: none;
  color: transparent;
}

.rbc-month-row+.rbc-month-row {
  border-top: 1px solid #f5f4f4;
}

.rbc-day-bg+.rbc-day-bg {
  border-left: 1px solid #f5f4f4;
}

.rbc-header {
  border-bottom: none;
}

.event-day {
  background-color: rgb(0, 180, 180) !important;
  color: white !important;
}

/* table coutries */
.bordTableCell {
  font-weight: 600 !important;
}

.rbc-header+.rbc-header {
  border-left: none;
}

.rbc-event.rbc-selected {
  background: none;
}

/* .rbc-event-continues-after{
  transform: skew(0eg, 0deg) !important;
}
.rbc-event-allday{
  transform: skew(-20deg, 0deg);
  border-radius: 3px 6px 3px 6px;
} */

.ChannelsIcon--bookingengine {
  background-color: #f7c31e;
}

.ChannelsIcon {
  border-radius: 50%;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 5px;
  flex-shrink: 0;
  background-size: contain;
  position: relative;
}

/* button modifier suprimer */
.statusButton {
  padding: 4px 10px;
  width: 90%;
  border: none;
  border-radius: 20px;
}

.actionButton {
  padding: 8px 10px;
  /* width: 80%; */
  width: 145px;
  border: none;
  border-radius: 20px;
  background-color: #c81f1f;
  color: white;
}

.actionDetails {
  padding: 8px 10px;
  /* width: 80%; */
  width: 145px;
  border: none;
  border-radius: 20px;
  background-color: #1fc824;
  margin-right: 5px;
  color: white;
}

/* .switchBase.Mui-checked+.MuiSwitch-track {
    background-color: #21bb6b;
} */
.css-15u6gmz-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: rgb(97, 238, 158) !important;
}

.css-15u6gmz-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: rgb(170, 244, 218) !important;
}

/* .css-12dqeey-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #41bfbf !important;
}
.css-7lij0j-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #41bfbf;
} */
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 24px;
}

/* ------------------------------------------------- */

.react-calendar__month-view__weekdays__weekday {
  text-decoration: none !important;
}

.react-calendar__month-view__days__day--weekend {
  color: black;
}

.react-calendar__tile--now {
  color: white;
  background: black;
  border-radius: 25px;
}

.slotsAccord {
  font-weight: 600;
  font-size: 16px;
  color: #2f4858;
}

.Label {
  background-color: #19b385;
  color: #fff;
  border-radius: 12px;
  font-size: 10px;
  padding: 2px 6px;
  text-transform: uppercase;
}

.UiFieldLabel {
  color: #788999;
  font-size: 1em;
  font-weight: normal !important;
  user-select: none;
  margin: 0 !important;
}

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

.date-picker-container {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.date-picker {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.custom-input {
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.custom-input:focus {
  outline: none;
  border-color: #007bff;
}

.calendar-icon {
  margin-left: 5px;
}

.custom-dialog-title {
  background-color: #f5f5f5;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.custom-dialog-content {
  padding: 20px;
}

.custom-dialog-actions {
  justify-content: flex-end;
  padding: 10px 20px;
}

.booking-details {
  margin-bottom: 8px;
}

.section-divider {
  margin: 16px 0;
}

.ReservationsPopupModalSlide-head {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #ddd;
}

.ReservationsPopupModalSlide-location {
  display: flex;
  align-items: center;
}

.ReservationsPopupModalSlide-location_label {
  margin-left: 8px;
}

.ReservationsPopupModalSlide-body {
  margin-top: 16px;
}

.ReservationsPopupModalSlide-section {
  margin-bottom: 16px;
}

.ReservationsPopupModalSlide-contact {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.ReservationsPopupModalSlide-contact_icon {
  margin-right: 8px;
}

.ReservationsPopupModalSlide-contact_link {
  color: #000;
  text-decoration: none;
}

.ReservationsPopupModalSlide-contact_link:hover {
  text-decoration: underline;
}

.ReservationsPopupModalSlide-section {
  margin-bottom: 20px;
  margin-top: 20px;
}

.ReservationsPopupModalSlide-section-title {
  margin-bottom: -5px;
  display: flex;
  align-items: center;
}

.FieldLabel {
  font-size: 11px;
  margin-bottom: 4px;
  color: #788999;
  display: block;
  font-weight: bold;
}

.ReservationsPopupModalSlide-reservation-id {
  margin-bottom: 10px;
}

.addNote {
  display: inline-block;
  margin-left: 10px;
  color: #00b4b4;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.4;
  text-decoration: none;
  cursor: pointer;
}

.react-calendar__tile {
  font-size: 12px;
}

.react-calendar__month-view__weekdays {
  text-transform: none;
  font-size: 12px;
  text-decoration: underline;
}

.react-calendar__navigation__arrow {
  display: none;
}

.react-calendar__navigation__label__labelText {
  color: #2f4858;
  font-size: 14px;
  font-weight: 700;
}

.rbc-row-bg {
  background: #f8f9fc !important
}

.react-calendar {
  border-bottom: transparent;
}

.Grid {
  box-sizing: border-box;
  display: flex;

}

.GridCell {

  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  cursor: pointer;
  margin: 0;

}

input[type='checkbox'] {
  accent-color: #1ea3a3;
}

/* Add this CSS in your main CSS file or a <style> block */
.p-column-header-content.header-center {
  text-align: center !important;
}

.p-datatable-thead .header-test {
  /* background-color: green;
  color: #000; */
  text-align: center;
}

.p-datatable-thead .header-test .p-column-header-content {
  display: block;
}


/* .p-column-header-content  .test {
  width: 20%;
   text-align: center;

} */
/* .p-datatable-sm .iBUKzs td {
  text-align: center;
} 
  */



.cl-internal-ovdovs {
  display: none;
}

.cl-internal-1p733cp {
  gap: 1rem;
}


.loader {
  position: relative;
  width: 2.5em;
  height: 2.5em;
  transform: rotate(165deg);
 }
 
 .loader:before, .loader:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.5em;
  height: 0.5em;
  border-radius: 0.25em;
  transform: translate(-50%, -50%);
 }
 
 .loader:before {
  animation: before8 2s infinite;
 }
 
 .loader:after {
  animation: after6 2s infinite;
 }
 
 @keyframes before8 {
  0% {
   width: 0.5em;
   box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
  }
 
  35% {
   width: 2.5em;
   box-shadow: 0 -0.5em rgba(225, 20, 98, 0.75), 0 0.5em rgba(111, 202, 220, 0.75);
  }
 
  70% {
   width: 0.5em;
   box-shadow: -1em -0.5em rgba(225, 20, 98, 0.75), 1em 0.5em rgba(111, 202, 220, 0.75);
  }
 
  100% {
   box-shadow: 1em -0.5em rgba(225, 20, 98, 0.75), -1em 0.5em rgba(111, 202, 220, 0.75);
  }
 }
 
 @keyframes after6 {
  0% {
   height: 0.5em;
   box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
  }
 
  35% {
   height: 2.5em;
   box-shadow: 0.5em 0 rgba(61, 184, 143, 0.75), -0.5em 0 rgba(233, 169, 32, 0.75);
  }
 
  70% {
   height: 0.5em;
   box-shadow: 0.5em -1em rgba(61, 184, 143, 0.75), -0.5em 1em rgba(233, 169, 32, 0.75);
  }
 
  100% {
   box-shadow: 0.5em 1em rgba(61, 184, 143, 0.75), -0.5em -1em rgba(233, 169, 32, 0.75);
  }
 }
 
 .loader {
  position: absolute;
  top: calc(50% - 1.25em);
  left: calc(50% - 1.25em);
 }