.Task {
    display: flex;
    flex-direction: column;
    /* height: 100vh; */
}

header {
    background-color: #f0f0f0;
    padding: 10px;
}

.main-content {
    display: flex;
    flex: 1;
}

aside {
    width: 200px;
    background-color: #e0e0e0;
    padding: 10px;
}

main {
    flex: 1;
    padding: 10px;
}

@media (min-width: 1200px) {
    .css-nkchhw {
margin-left: 0% !important;
    }
}