.calendar-container {
  overflow-x: auto;
}

.tableMulti {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}
tbody tr{
  height: 55px;

}
.tableMulti th,
.tableMulti td {
  /* padding: 8px; */
  text-align: center;
  border: none;
  width: 300px;

}

.listing-name {
  white-space: nowrap;
  text-align: left;
  font-size: 14px;
  background: white;
    margin-left: 100px;
    position: absolute;
    z-index: 100;
    left: -76px;
    width: 289px;
    padding: 18px;
    display: flex;


}

.day-cell {
  background-color: #f0f2f5;
  padding: inherit;
  text-align: center;
  cursor: pointer;

}
.day-cell:hover {
  background-color: #ddd;

}

.reservation-cell {
  background-color: #00b4b4;
  color: white;
  text-align: center;
  font-weight: bold;
  transform: skew(-20deg, 0deg);
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;

}

.reservation-cell span {
  position: relative;
  z-index: 2;
}


.btnConatiners{
  height: 89px;
  display: flex;
  align-items: center;
  position: relative;
  left: 0px;
  width: 289px;
  background: rgb(255, 255, 255);
  height: 49px;
  top: 50px;

}
.textConrol{
  display: block;
  width: 191px;
  text-overflow: ellipsis;
  overflow: hidden;

}
.idConrol{
  display: block;

}
.PageLimit {
  color: #4e525d;
  font-size: 14px;
}
.btnsNest{
  height: 89px;
  display: flex;
  align-items: center;
  width: 108px;
  background: rgb(255, 255, 255);

}
 
.formaDate{
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: #091e42;
} 

