.table-calendar-container {
    width: 100%;
    overflow: hidden;
}

.table-calendar {
    width: 100%;
    border-collapse: collapse;
}

.property-cell {
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.property-name {
    font-weight: 600;
    color: #333;
    margin-bottom: 4px;
}


.room-type-name {
    font-weight: 500;
    color: #666;
    font-size: 0.9em;
}

.group-header-cell {
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: #f5f5f5;
    font-weight: 600;
}

.group-header-icon {
    margin-right: 8px;
    font-size: 20px;
}

.zone-row {
    background-color: #f5f5f5;
}
.zone-row-Inventory {
    background-color: #36363b;
}

.property-row:hover {
    background-color: #f8f9fa;
}

.cursor-pointer {
    cursor: pointer;
}

.p-datatable .p-datatable-thead > tr > th {
    background-color: #f8f9fa;
    padding: 8px;
    text-align: center;
    font-weight: 600;
}

/* .p-datatable .p-datatable-tbody > tr > td {
    padding: 0;
    border: 1px solid #dee2e6;
} */

.p-datatable-scrollable-header {
    background-color: #f8f9fa;
    border-bottom: 2px solid #dee2e6;
}

.p-datatable-scrollable-body {
    overflow-y: auto;
}




.pending-change {
    position: relative;
}

.pending-indicator {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 8px;
    height: 8px;
    background-color: #fbbf24;
    border-radius: 50%;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.price-change {
    transition: all 0.2s ease;
}

.price-change:hover .pending-tooltip {
    opacity: 1;
    visibility: visible;
}

.pending-tooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 8px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 4px;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
    white-space: nowrap;
    z-index: 10;
}